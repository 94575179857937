<template>
  <div class="comp-train">
    <!-- 培训情况 -->

    <div
      class="education"
      ref="educationDom"
      v-loading="chartLoading"
      element-loading-text="图表渲染中"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      education_chart: "", // 教育培训图形实例
      chartLoading: false, // 图例加载

      jobEmployeeNum: "",
    };
  },
  methods: {
    // 组件初始化
    init(propData, jobEmployeeNum) {
      this.jobEmployeeNum = jobEmployeeNum;
      this.educationInit(propData);

      this.resizeHandler();
    },
    // 监听窗口变化，重构echart图形
    resizeHandler() {
      window.onresize = () => {
        this.education_chart.resize();
      };
    },
    // 教育培训视图初始化
    educationInit(propData) {
      let notPassNum = [];
      let notTestNum = [];
      let passNum = [];
      let testNum = [];

      let dataNum = [];

      let state = propData[0];
      for (let i = 0; i < 10; i++) {
        propData.push(state);
      }

      propData.forEach((ele, idx) => {
        notPassNum.push(ele.notPassNum);
        notTestNum.push(ele.notTestNum);
        passNum.push(ele.passNum);
        testNum.push(ele.testNum);

        dataNum.push("试卷" + (parseInt(idx) + 1));
      });

      this.education_chart = this.$echarts.init(this.$refs.educationDom);

      let option = {
        legend: {
          bottom: 0,
          left: "center",
          icon: "circle",
          itemGap: 33,
          textStyle: {
            fontSize: 12,
            color: "rgba(78, 89, 105, 1)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          top: "26",
        },
        dataZoom: [
          {
            type: "slider",
            // show: true,
            // // 设置组件控制的y轴
            yAxisIndex: 0,
            // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%
            // 也可以用 startValue设置起始值
            startValue: 0,
            endValue: 4,
            width: 12,
            // height: 450,
            // 组件的背景颜色
            // backgroundColor: "rgba(0, 0, 0, 0.4)",
            // 选中范围的填充颜色
            // fillerColor: "#00FFFF",
            // 边框颜色
            borderColor: "#0094E9",
            // 是否显示detail，即拖拽时候显示详细数值信息
            showDetail: false,
            // 控制手柄的尺寸
            handleSize: 16,
            zoomLock: true,
          },
          {
            type: "inside",
            yAxisIndex: [0],

            // 不按任何功能键，鼠标滚轮能触发缩放
            zoomOnMouseWheel: false,
            // 不按任何功能键，鼠标移动能触发数据窗口平移
            moveOnMouseWheel: true,
          },
        ],
        xAxis: {
          type: "value",
          min: 0,
          max: this.jobEmployeeNum * 1.5,
          splitNumber: 20,
          splitLine: {
            lineStyle: {
              color: "rgba(242, 243, 245, 1)",
            },
          },
        },
        yAxis: {
          type: "category",
          axisTick: false,
          data: dataNum,
          inverse: true,
        },

        series: [
          {
            name: "考试人数",
            data: testNum,
            type: "bar",
            stack: "one",
            itemStyle: {
              color: "#EFBD42",
            },
          },
          {
            name: "未考试人数",
            data: notTestNum,
            type: "bar",
            stack: "one",
            itemStyle: {
              color: "#EA4026",
            },
          },
          {
            name: "未合格人数",
            data: notPassNum,
            type: "bar",
            stack: "two",
            itemStyle: {
              color: "#4AB2DC",
            },
          },
          {
            name: "合格人数",
            data: passNum,
            type: "bar",
            stack: "two",
            itemStyle: {
              color: "#009D86",
            },
          },
        ],
      };

      option && this.education_chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.education {
  height: 530px;
}
</style>